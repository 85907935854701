.main {
  margin-top: 3%;
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  display: block;
  margin: 0 auto;
}

.up {
  display: flex;
  gap: 0.5em;
  /* padding: 0 23%; */
  margin-bottom: 0.5em;
  padding: 0 23%;
}

.down {
  display: flex;
  gap: 0.5em;

  padding: 0 23%;
}

.card1 {
  width: 90px;
  height: 90px;
  outline: none;
  border: none;
  background: white;
  border-radius: 90px 5px 5px 5px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  transition: 0.2s ease-in-out;
}

.instagram {
  margin-top: 1.5em;
  margin-left: 1.2em;
  fill: #cc39a4;
}

.card2 {
  width: 90px;
  height: 90px;
  outline: none;
  border: none;
  background: white;
  border-radius: 5px 90px 5px 5px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  transition: 0.2s ease-in-out;
}

.twitter {
  margin-top: 1.5em;
  margin-left: -0.9em;
  fill: #03a9f4;
}

.card3 {
  width: 90px;
  height: 90px;
  outline: none;
  border: none;
  background: white;
  border-radius: 5px 5px 5px 90px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  transition: 0.2s ease-in-out;
}

.github {
  margin-top: -0.6em;
  margin-left: 1.2em;
}

.facebook {
  margin-top: -0.6em;
  margin-left: 1.2em;
  fill: #1976f2;
}

.card4 {
  width: 90px;
  height: 90px;
  outline: none;
  border: none;
  background: white;
  border-radius: 5px 5px 90px 5px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  transition: 0.2s ease-in-out;
}

.discord {
  margin-top: -0.9em;
  margin-left: -1.2em;
  fill: #8c9eff;
}

.youtube {
  margin-top: -0.9em;
  margin-left: -1.2em;
  fill: #ff0101;
}

.card1:hover {
  cursor: pointer;
  scale: 1.1;
  background-color: #cc39a4;
}

.card1:hover .instagram {
  fill: white;
}

.card2:hover {
  cursor: pointer;
  scale: 1.1;
  background-color: #03a9f4;
}

.card2:hover .twitter {
  fill: white;
}

.card3:hover {
  cursor: pointer;
  scale: 1.1;
  background-color: #1976f2;
}

.card3:hover .facebook {
  fill: white;
}

.card4:hover {
  cursor: pointer;
  scale: 1.1;
  background-color: #ff0101;
}

.card4:hover .youtube {
  fill: white;
}

@media (max-width: 1200px) {
  .up {
    padding: 0 18%;
  }
  .down {
    padding: 0 18%;
  }
}

@media (max-width: 330px) {
  .up {
    padding: 0;
  }
  .down {
    padding: 0;
  }
}
