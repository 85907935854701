#timeline {
  display: flex;
  flex-direction: column;
  background: #1d1f32;
}

@media (650px <= width <= 1170px) {
  #timeline {
    padding: 0 30%;
  }
}

@media (650px <= width <= 800px) {
  #timeline {
    padding: 0 20%;
  }
}

@media (width < 650px) {
  #timeline {
    padding: 0 15%;
  }
}

@media (width < 550px) {
  #timeline {
    padding: 0 10%;
  }
}

/* .css-1sj2t5c {
  width: 110%;
} */

.timeline-icon {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.timeline-img {
  width: 80%;
  height: 80%;
  max-width: 100%;
  max-height: 100%;
  margin: auto;
  border-radius: 8%;
}
