@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@300&display=swap");

@font-face {
  font-family: homeTitle;
  src: url("../webfonts/NOISEWARE.ttf");
}

.junoon-txt {
  display: none;

  margin: 0;
  font-size: 12rem;
  color: #ffffff;
}

.app__header {
  min-height: 100vh;
  width: 100%;

  padding: 0;
  margin: 0;
}

.home-title {
  margin: 15% 15% 5% 10%;
  transform: rotate(-8deg);
}

.home-title {
  color: var(--color-golden);
  font-weight: 100;
  letter-spacing: 0.04em;
  text-transform: capitalize;
  font-size: 6rem;
}

.home-title-container {
  width: 60%;
  padding-bottom: 0;
  margin-top: 100px;
}

.typeWriter-txt {
  font-size: 10.8rem;
  color: #ffffff;
}

.app__wrapper_info {
  text-align: left;
  width: 20%;
}

.app__header-img img {
  width: 80%;
}

/* @media (max-width: 1100px) {
  .app__header {
    background: rgba(0, 0, 0, 0.3);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(15px);
    -webkit-backdrop-filter: blur(15px);
  }

  .junoon-txt {
    display: block;
  }
} */

@media (max-width: 1150px) {
  .app__wrapper {
    flex-direction: row;
  }

  .typeWriter-txt {
    font-size: 10rem;
  }
}

@media (max-width: 1050px) {
  .app__header {
    background: rgba(0, 0, 0, 0.3);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(15px);
    -webkit-backdrop-filter: blur(15px);
  }

  .junoon-txt {
    display: block;
    padding: 0;
  }

  .app__wrapper {
    flex-direction: column;
    justify-content: center;
    align-content: center;
  }

  .home-title {
    transform: rotate(0deg);
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 80px;
  }

  .home-title-container {
    width: 100%;
    margin-top: 0px;
    align-self: center;
    /* 
    padding-bottom: 0;
    margin-top: 250px;
    margin-left: 0; */
  }

  .typeWriter-txt {
    font-size: 8rem;
  }

  .junoon-txt {
    font-size: 10rem;
  }

  .typed-1 {
    color: #09e38f;
  }
  .typed-2 {
    color: #cfad31;
  }

  .typed-3 {
    color: rgb(254, 34, 70);
  }
}

@media (max-width: 600px) {
  .typeWriter-txt {
    font-size: 8rem;
  }

  .home-title {
    gap: 80px;
  }
}

@media (max-width: 500px) {
  .typeWriter-txt {
    font-size: 6rem;
  }

  .junoon-txt {
    font-size: 8rem;
  }

  .home-title {
    gap: 40px;
  }
}

@media (max-width: 370px) {
  .typeWriter-txt {
    font-size: 5.8rem;
  }
}

@media (max-width: 350px) {
  .typeWriter-txt {
    font-size: 5rem;
  }

  .junoon-txt {
    font-size: 7rem;
  }

  .home-title {
    gap: 20px;
  }
}

@media (max-width: 300px) {
  .typeWriter-txt {
    font-size: 4rem;
  }
  .junoon-txt {
    font-size: 6rem;
  }
  .home-title {
    gap: 0;
  }
}

.Typewriter__cursor {
  color: whitesmoke;
}

/* * {
  margin: 0;
  padding: 0;
}

html,
body {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

body {
  background-color: #021027;
}

.container {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
}

.background {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  width: 100%;
  height: 100%;

  mask-image: radial-gradient(
    white 0%,
    white 30%,
    transparent 80%,
    transparent
  );
}

.circle-container {
  $particleNum: 200;
  $particleColor: hsl(180, 100%, 80%);

  position: absolute;
  transform: translateY(-10vh);
  animation-iteration-count: infinite;
  animation-timing-function: linear;

  .circle {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    mix-blend-mode: screen;
    background-image: radial-gradient(
      hsl(180, 100%, 80%),
      hsl(180, 100%, 80%) 10%,
      hsla(180, 100%, 80%, 0) 56%
    );

    animation: fadein-frames 200ms infinite, scale-frames 2s infinite;

    @keyframes fade-frames {
      0% {
        opacity: 1;
      }

      50% {
        opacity: 0.7;
      }

      100% {
        opacity: 1;
      }
    }

    @keyframes scale-frames {
      0% {
        transform: scale3d(0.4, 0.4, 1);
      }

      50% {
        transform: scale3d(2.2, 2.2, 1);
      }

      100% {
        transform: scale3d(0.4, 0.4, 1);
      }
    }
  }

  $particleBaseSize: 8;

  
}


 

   */
